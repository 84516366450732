@font-face {
	font-family: "kfont";
	src: url('../fonts/kfont.eot');
	src: url('../fonts/kfont.eot?#iefix') format('eot'),
	url('../fonts/kfont.woff2') format('woff2'),
	url('../fonts/kfont.woff') format('woff'),
	url('../fonts/kfont.ttf') format('truetype'),
	url('../fonts/kfont.svg#kfont') format('svg');
	font-weight: normal;
    font-style: normal;
    font-display: block;
}

body .icon-facebook:before,
body .icon-instagram:before,
body .icon-linkedin:before,
body .icon-pinterest:before,
body .icon-twitter:before,
body .icon-youtube:before,
body .icon-arrow-left:before,
body .icon-arrow-right:before,
body .icon-arrow-top:before,
body .icon-bottom:before,
body .icon-left:before,
body .icon-right:before,
body .icon-top:before,
body .icon-history:before,
body .icon-information:before,
body .icon-cart:before,
body .icon-wishlist:before,
body .icon-arrow-bottom:before,
body .icon-user:before,
body .icon-eye:before,
body .icon-heart:before,
body .icon-phone:before,
body .icon-address:before,
body .icon-voucher:before,
body .icon-schedule:before,
body .icon-delete:before,
body .icon-new-address:before,
body .icon-main-address:before,
body .icon-grid:before,
body .icon-table:before,
body .icon-moins:before,
body .icon-plus:before,
body .icon-hours:before,
body .icon-email:before,
body .icon-mapmarker:before,
body .icon-search:before,
body .icon-lock:before,
body .icon-lock-open:before,
body .icon-power-off:before,
body .icon-circle-chevron-left:before,
body .icon-bars:before,
body .icon-file:before,
body .icon-square-twitter:before,
body .icon-square-facebook:before,
body .icon-square-youtube:before,
body .icon-square-phone:before,
body .icon-square-email:before,
body .icon-vague-bandeau:before,
body .icon-arrow-right-long:before,
body .icon-angle-right-solid:before,
body .icon-clock-regular:before,
body .icon-envelope-open-solid:before,
body .icon-phone-solid:before {
  font-family: "kfont";
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  display: inline-block;
  text-decoration: inherit;
}

body .icon-facebook:before { content: "\EA01" }
body .icon-instagram:before { content: "\EA02" }
body .icon-linkedin:before { content: "\EA03" }
body .icon-pinterest:before { content: "\EA04" }
body .icon-twitter:before { content: "\EA05" }
body .icon-youtube:before { content: "\EA06" }
body .icon-arrow-left:before { content: "\EA07" }
body .icon-arrow-right:before { content: "\EA08" }
body .icon-arrow-top:before { content: "\EA09" }
body .icon-bottom:before { content: "\EA0A" }
body .icon-left:before { content: "\EA0B" }
body .icon-right:before { content: "\EA0C" }
body .icon-top:before { content: "\EA0D" }
body .icon-history:before { content: "\EA0E" }
body .icon-information:before { content: "\EA0F" }
body .icon-cart:before { content: "\EA10" }
body .icon-wishlist:before { content: "\EA11" }
body .icon-arrow-bottom:before { content: "\EA12" }
body .icon-user:before { content: "\EA13" }
body .icon-eye:before { content: "\EA14" }
body .icon-heart:before { content: "\EA15" }
body .icon-phone:before { content: "\EA16" }
body .icon-address:before { content: "\EA17" }
body .icon-voucher:before { content: "\EA18" }
body .icon-schedule:before { content: "\EA19" }
body .icon-delete:before { content: "\EA1A" }
body .icon-new-address:before { content: "\EA1B" }
body .icon-main-address:before { content: "\EA1C" }
body .icon-grid:before { content: "\EA1D" }
body .icon-table:before { content: "\EA1E" }
body .icon-moins:before { content: "\EA1F" }
body .icon-plus:before { content: "\EA20" }
body .icon-hours:before { content: "\EA21" }
body .icon-email:before { content: "\EA22" }
body .icon-mapmarker:before { content: "\EA23" }
body .icon-search:before { content: "\EA24" }
body .icon-lock:before { content: "\EA25" }
body .icon-lock-open:before { content: "\EA26" }
body .icon-power-off:before { content: "\EA27" }
body .icon-circle-chevron-left:before { content: "\EA28" }
body .icon-bars:before { content: "\EA29" }
body .icon-file:before { content: "\EA30" }
body .icon-square-twitter:before { content: "\EA31" }
body .icon-square-facebook:before { content: "\EA32" }
body .icon-square-youtube:before { content: "\EA33" }
body .icon-square-phone:before { content: "\EA34" }
body .icon-square-email:before { content: "\EA35" }
body .icon-vague-bandeau:before { content: "\EA36" }
body .icon-arrow-right-long:before { content: "\EA37" }
body .icon-angle-right-solid:before { content: "\EA38" }
body .icon-clock-regular:before { content: "\EA39" }
body .icon-envelope-open-solid:before { content: "\EA40" }
body .icon-phone-solid:before { content: "\EA41" }
